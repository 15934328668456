/* Add a gray background color with some padding */
.Cardcontainer {
  background: #000000;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.CardHomeBanner {
  position: relative;
  background: url("../../assets/images/contact-banner.png");
  background-size: cover;
  background-position: center;

  padding: 200px 0;
}

.opicity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000000d0;
}
/* Header/Blog Title */
.Cardheader {
  padding: 30px;

  text-align: center;
  position: sticky;
  color: white;
}
.Cardheader h2 {
  font-size: 5rem;
  font-weight: 900;
}
.Card2ndContainer {
  max-width: 1640px;
  margin: 0px auto;
  padding-bottom: 4rem;
}

.PrivledgeCardsHeader h2 {
  margin: 2rem 1rem;
  text-align: center;
  color: white;
  font-size: 4rem;
  font-weight: 700;
}
.PrivledgeCards {
  padding: 4rem 3rem;

  margin: 0px 1rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 50px;
  background: linear-gradient(135deg, #00014d, #001b6dee);
  place-items: center;
}

.flipContainer {
  perspective: 1000px; /* Adds depth to the 3D effect */
  max-width: 1000px;
  width: 100%;

  max-height: 500px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

.flipbox {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Ensures the child elements are positioned in 3D */
  transition: transform 0.6s ease-in-out;
}

.flipContainer:hover .flipbox {
  transform: rotateY(180deg); /* Flip the box when hovered */
}

/* Front of the box */
.flipboxfront,
.flipboxback {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back side when flipped */
  display: flex;
  justify-content: center;

  font-family: Arial, sans-serif;
  text-align: center;
  cursor: pointer;
}

.flipboxfront {
  align-items: center;
}

.flipboxback {
  height: 100%;
  transform: rotateY(180deg); /* Rotate the back side initially */
  padding: 20px;
  overflow-y: auto;
  border: 1px solid rgb(56, 51, 112);
}

@media (max-width: 900px) {
  .flipContainer {
    max-height: 500px;
    width: 100%;
    height: 500px;
  }
}
@media (max-width: 720px) {
  .flipContainer {
    max-height: 400px;
    width: 100%;
  }
}
@media (max-width: 620px) {
  .flipContainer {
    max-height: 300px;
    width: 100%;
  }
}
@media (max-width: 520px) {
  .flipContainer {
    max-height: 250px;
    width: 100%;
  }
}
@media (max-width: 420px) {
  .flipContainer {
    max-height: 250px;
    width: 100%;
  }
}
.PrivledgeCardsDiv {
  box-shadow: 0px 0px 55px 5px rgba(255, 255, 255, 0.203);
}
.PrivledgeCards img {
  width: 100%;
  border: 2px solid white;
  border-radius: 10px;
}

.privilegeCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}

.privilegeCard {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  background: linear-gradient(135deg, #00438f, #005c50);
  padding: 40px 35px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.privilegeCardTitle {
  font-size: 22px;
  font-weight: bold;
  color: #00dbe6;
}

.privilegeCardSubtitle {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
}

.privilegeCardText {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
}

.privilegeCardHeading {
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
}

.privilegeCardList {
  list-style: none;
  padding: 0;
  text-align: left;
}

.privilegeCardListItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
}

.privilegeIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: #e6006f;
}

.privilegeCardButton {
  width: 100%;
  background: #e60000;
  color: white;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 15px;
}

.privilegeCardButton:hover {
  background: #cc0000;
}

.privilegeCardCenter {
  text-align: center;
}

@media (max-width: 700px) {
  .CardHomeBanner {
    padding: 160px 0px;
  }
  .Cardheader h2 {
    font-size: 3.8rem;
  }
  .PrivledgeCards {
    padding: 1.5rem;
  }
  .privilegeCard {
    padding: 35px 20px;
  }
  .privilegeCardContainer {
    padding: 0px;
  }
}
@media (max-width: 500px) {
  .CardHomeBanner {
    padding: 120px 0px;
  }
  .Cardheader h2 {
    font-size: 3.2rem;
  }
  .PrivledgeCardsHeader h2 {
    font-size: 3rem;
  }
  .PrivledgeCards {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  .PrivledgeCards {
    padding: 1rem;
  }
  .privilegeCard {
    padding: 1rem;
  }
}

@media (max-width: 420px) {
  .Cardheader h2 {
    font-size: 2.8rem;
  }
}
